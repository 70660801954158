<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="text-black text-left mb-0">Category</h4>
      </div>
      <div
        class="d-flex justify-content-between active card-body billpayment-menu"
        v-for="(list, index) in categoryList"
        :key="index"
      >
        <h6 class="card-title mb-0">{{ list }}</h6>
        <b-icon-chevron-right></b-icon-chevron-right>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isCategory: false,
      healthMarketId: "628371f94b367714f38740e6",
    };
  },
  computed: {
    ...mapState({
      marketList: (state) => state["home"].marketList,
      categoryList: (state) => state.healthCare.healthCareCategoryList,
    }),
  },
  async mounted() {
    await this.$store.dispatch(
      "healthCare/fetchHealthCareCategoryData",
      this.healthMarketId
    );
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style>
.height {
  height: 50px !important;
  background-color: #f9f9f9 !important;
}
.active {
  color: #00a0a8;
}

/* .category {
  width: 100%;
  height: 350px;
}
.color {
  color: #fe7b08;
}
.pointer:hover {
  color: #fe7b08;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
} */
.billpayment-menu .router-link-exact-active {
  color: #ff6a2e !important;
}
</style>
